.startApplying {
  font-weight: bold;
  margin: 20px 0px 0px 20px;
  position: relative;
  width: auto;
  display: inline-block;
}
.startApplying::after {
  content: "";
  height: 2px;
  width: 45%;
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
}

.linkedinSubmitButton {
  display: flex;
  margin: 20px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  color: #007bff;
  font-weight: bold;
  border: 1px solid #007bff;
  border-radius: 10px;
  text-transform: uppercase;
}

.linkedinSubmitButton:hover {
  background-color: #007bff;
  color: white;
}

.linkedinSubmitButton i {
  margin-left: 5px;
}

.buttonGoBack {
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid;
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
}

.buttonPrivacy {
  right: 130px;
  background-color: red;
  color: white;
}

.buttonPrivacy:hover {
  background-color: red !important;
  color: white !important;
}

.buttonSetup {
  right: 130px;
}

.additionalInfo {
  position: absolute;
  top: 10px;
  border: 1px solid;
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
  background-color: #007bff;
  color: white;
  right: 140px;
  font-weight: bold;
}

.additionalInfo:hover {
  background-color: #0070ff;
  color: white;
}

.buttonGoBack i {
  margin-right: 5px;
  color: black;
}

.buttonGoBack:hover {
  background-color: #007bff;
  color: white;
}

.buttonGoBack:hover i {
  color: white;
}

.progressbars {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 20px;
  width: 300px;
  font-size: 13px;
}

.progress-dones {
  background: linear-gradient(to left, #f2709c, #ff9472);
  box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.linkedinInit {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-bottom: 50px;
  border: 1px solid #efefef;
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
}

.linkedinInitTop {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  padding: 10px;
  color: grey;
  font-size: 15px;
  font-weight: bold;
  margin-top: 15px;
}

.linkedinInitTop1 {
  display: flex;
  padding: 10px;
  color: grey;
  font-size: 15px;
  font-weight: bold;
  margin-top: 15px;
  justify-content: center;
}

.jobsApplied {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  font-size: 30px;
  border-radius: 20px;
  margin-left: 40px;
}

.processing {
  text-align: center;
  width: 100%;
  margin: 70px;
  height: 8px;
  background-color: #ddd;
  border-radius: 4px;
  line-height: 2em;
}

.processing:before {
  position: relative;
  background: #98c24e;
  -webkit-animation: progress 3s infinite;
  animation: progress 3s infinite;
  border-radius: 4px;
  display: block;
  content: "";
  height: 8px;
}

@-webkit-keyframes progress {
  0% {
    left: 0;
    width: 25%;
  }
  50% {
    left: 75%;
    width: 25%;
  }
  100% {
    left: 0;
    width: 25%;
  }
}

@keyframes progress {
  0% {
    left: 0;
    width: 25%;
  }
  50% {
    left: 75%;
    width: 25%;
  }
  100% {
    left: 0;
    width: 25%;
  }
}

.linkedinReset {
  position: absolute;
  top: 5px;
  left: 5px;
  border: 1px solid;
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
}

.linkedinReset:hover {
  background-color: #007bff;
  color: white;
}

.filterReset {
  border: 1px solid red;
  color: red;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  padding: 8px 10px;
  position: absolute;
  top: -10px;
  right: -10px;
}

.filterReset:hover {
  background-color: red;
  color: white;
}

.under10 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 0;
}

.select__menu {
  position: absolute;
  z-index: 100000;
}

.stepswithcutmain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 20px;
  height: 100%;
  width: fit-content;
  border-radius: 10px;
  box-shadow: 0 2px 2px 2px #e8e8e8, 0 2px 2px 2px #e8e8e8;
}

.stepswithcutmainheading {
  font-size: 30px;
  color: black;
  text-align: center;
  margin-bottom: 30px;
}

.makesure {
  font-size: 16px;
  font-weight: bold;
  color: darkblue;
  font-style: italic;
}

/* exact search */
.exactsearch {
  display: flex;
  background: whitesmoke;
  width: fit-content;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.knowwhat {
  font-size: 12px;
  margin-top: -10px;
  margin-left: 10px;
  color: blue;
}

.exactsearchchild {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.exactsearch label {
  margin: 0px 15px;
}

.exactsearch .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 27px;
  border-radius: 27px;
  box-shadow: inset 0px 0px 0px 1px #bdc3c7;
  cursor: pointer;
  transition: all 0.3s;
}
.exactsearch .switch input {
  display: none;
}
.exactsearch .switch input:checked + div {
  background-color: #3498db;
  left: calc(100% - 25px);
}
.exactsearch .switch div {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: rgb(150, 150, 150);
  top: 1px;
  left: 0px;
  transition: all 0.4s;
}

.exactsearch .switch-checked {
  box-shadow: inset 0px 0px 0px 1px #3498db;
}

/*SWITCH 3 ------------------------------------------------*/

.exactsearch .switch3 {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 37px;
  border-radius: 37px;
  background-color: #f3f4f4;
  cursor: pointer;
  transition: all 0.3s;
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid black;
}
.exactsearch .switch3 input {
  display: none;
}
.exactsearch .switch3 input:checked + div {
  left: calc(80px - 32px);
  box-shadow: 0px 0px 0px white;
}
.exactsearch .switch3 div {
  position: absolute;
  width: 27px;
  height: 27px;
  border-radius: 27px;
  background-color: white;
  top: 5px;
  left: 5px;
  box-shadow: 0px 0px 1px rgb(150, 150, 150);
  transition: all 0.3s;
}
.exactsearch .switch3 div:before,
.switch3 div:after {
  position: absolute;
  content: "ON";
  width: calc(80px - 40px);
  height: 37px;
  line-height: 37px;
  font-family: "Varela Round";
  font-size: 14px;
  font-weight: bold;
  top: -5px;
}
.exactsearch .switch3 div:before {
  content: "OFF";
  color: black;
  left: 100%;
}
.exactsearch .switch3 div:after {
  content: "ON";
  right: 100%;
  color: white;
}

.exactsearch .switch3-checked {
  background-color: teal;
  box-shadow: none;
}

.advanceSearchContainer {
  background-color: whitesmoke;
  padding: 0px 20px;
  display: flex;
  width: 100%;
  color: black;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-top: 30px;
}

.addAdvanceSearchKeywordHint {
  color: green;
  font-size: 12px;
  font-weight: bold;
}

.advanceSearchInputBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.advanceSearchInputBlockLeft {
  width: 100%;
  border: 10px 0 0 10px;
  border: 0.5px solid gray;
}

.advanceSearchInputBlockRight {
  width: 200px;
  height: max-content;
}

.advanceSearchInputBlockRight button {
  width: 100%;
}

.advanceSearchTags {
  margin-top: 20px;
  margin-bottom: 20px;
}

.advanceSearchTags span {
  background-color: orange;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.advanceSearchResponse {
  margin-top: 10px;
  padding: 10px;
  color: green;
  font-weight: bold;
  font-size: 16px;
}

.advanceSearchInputBlockRight button {
  border: none;
  padding: 8px;
  padding: 8px;
  background: #007bff;
  color: white;
  border-radius: 0 10px 10px 0;
}

.advanceSearch {
  padding: 10px;
}

.advanceSearchHeading {
  font-weight: bold;
  font-size: 20px;
  margin: 10px;
}

.advanceSearchContainerLeft {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
}

.advanceSearchKnowMore {
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
}

.advanceSearchKnowMoreLink {
  color: #007bff;
  margin-left: 10px;
}

.advanceSearchSaveConfiguration {
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  outline: none;
  background-color: #007bff;
  color: white;
}

.myclass {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  display: flex;
  padding: 0px 0px 0px 0px;
  min-width: 400px;
  overflow: hidden;
}
.redBox {
  background-color: #cb112d;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex: 0 0 11.5%;
  height: 100%;
}

.textDiv {
  padding: 2px 2px 2px 2px;
  margin: 7px 4px 7px 10px;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
}
.textDiv .lower {
  color: #666666;
}
.textDiv .upper {
  font-weight: bold;
}
.lknResumeFull {
  padding: "20px";
  width: "100%";
  margin: 0px 20px 0px 10px;
}

.lknResumeFullBorder {
  border: 3px solid rgb(20, 60, 188);
  padding: "20px";
  width: "100%";
  border-radius: 20px;
  margin: 30px 20px 30px 20px;
}

.custom-radio .form-check-input {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

.lknResumeCol {
  margin: 10px 10px 10px 20px;
}
@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700");

.wrapper {
  font-family: Lato, sans-serif;
  padding: 0;
  box-sizing: border-box;
  background-color: #def1fd;
  border-radius: 20px;
  padding: 20px 50px 50px 50px;
  /* background-color: #f5f5f5; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0 0 50px;
  width: 60%;
  /* flex: 1 */
}

.wrapper h1 {
  margin-bottom: 20px;
}

.wrapper .container {
  background-color: white;
  color: black;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgb(0, 0, 0, 0.25);
  margin: 20px 0px 20px;
  display: flex;
  flex-direction: column;
}

.wrapper .question {
  font-size: 1.1rem;
  font-weight: 200;
  padding: 10px 80px 10px 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #878c9a;
}

.wrapper .question::after {
  content: "\002B";
  font-size: 2.2rem;
  position: absolute;
  right: 20px;
  transition: 0.2s;
}

.wrapper .question.active::after {
  transform: rotate(45deg);
}

.wrapper .answercont {
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
}
.answerPointsDiv {
  margin-top: 20px;
  margin-left: 20px;
}

.wrapper .answer {
  padding: 0 20px 20px;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper .myVideoClass {
  height: 360px;
  width: 640px;
}

@media screen and (max-width: 790px) {
  html {
    font-size: 14px;
  }
  .wrapper {
    width: 80%;
  }

  .editresumeheading {
    padding-top: 100px;
  }

  .v2resumeAlreadyPresent {
    margin-top: 20px;
  }
}

.fbFaqContainer {
  display: flex;
}
.fbContainer {
  flex: 1;
  margin: 50px 0 0 50px;
}

.issuesHeader {
  margin: 20px 0px 20px;
}

.featuresImage {
  width: 100%; /* ensures video takes up full width of its container */
  height: 100%; /* ensures video takes up full height of its container */
  object-fit: contain; /* ensures video keeps its aspect ratio */
}

.errorUl {
  width: 90%;
}

.planPurchaseRequiredMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.planPurchaseRequired {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  color: cadetblue;
}

.planPurchaseRequiredMainDiv .button1{
  font-weight: 500;
  width: 150px;
  outline: none;
  border: none;
  color: white;
  background: cadetblue;
  padding: 10px;
  border-radius: 5px;
}
