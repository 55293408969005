.invite_user_main_block {
  margin: 20px;
  padding: 20px;
  position: relative;
  box-shadow: 0px 1px 1px 2px #efefef, 0px 1px 1px 2px #efefef;
}

.invite_user_headline {
  color: #007bff;
  font-size: 20px;
  font-weight: bold;
  margin: 20px;
}

.invite_user_select_option_heading {
  color: darkblue;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.invite_user_select_option_main_block {
  background-color: #eef3ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 10px;
  height: 200px;
  font-size: 20px;
  color: #05897b;
  font-weight: 600;
  border: 2px solid #05897b;
}

.invite_user_select_option_main_block:hover {
  background-color: #d5e1ff;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0px 1px 1px 2px #efefef, 0px 1px 1px 2px #efefef;
}

.invite_user_select_option {
  margin: 20px;
  padding: 20px;
}

.invite_user_main_button {
  margin: 20px;
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: darkcyan;
  color: white;
  font-size: 15px;
}

.invite_user_error_success {
  margin: 20px;
  font-weight: 600;
}

.invite_user_error {
  color: red;
}

.invite_user_success {
  color: green;
}

.invite_user_form_input_block {
  margin: 20px 0px;
}

.user_invite_invitations {
  padding: 10px;
  border-radius: 10px;
  background-color: aliceblue;
}

.user_invite_invitations .row {
  margin: 0px;
}

.invite_user_view_all {
  margin: 20px;
  color: #007bff;
  cursor: pointer;
  font-size:16px;
  text-decoration: underline;
}

.user_invite_invitations_heading {
  color: #007bff;
  font-size: 20px;
  font-weight: bold;
}

.user_invite_invited_by span {
  color: darkblue;
  font-weight: bold;
}

.user_invite_invited_by {
  font-weight: normal;
  display: flex;
  justify-content: start;
  align-items: center;
}

.user_invite_accept_invitation {
  background-color: darkcyan;
  width: 100%;
  padding: 10px 20px;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
}

.user_invite_invitation_button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.user_invite_invitations_block {
  padding: 20px;
  margin: 20px;
  position: relative;
  box-shadow: 0px 1px 1px 2px #efefef, 0px 1px 1px 2px #efefef;
}

.invite_your_invitations_block {
  position: relative;
  padding-top: 50px;
}

.user_invite_accepted_invitation {
  color: darkcyan;
  font-weight: bold;
}

.invite_user_main_block_back_button {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #007bff;
  color: white;
  outline: none;
  border: none;
  border-radius: 10px;
}

.invite_user_main_block_back_button:hover {
  cursor: pointer;
  color: white;
}
