.buttontarget {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 30px;
}

.buttontarget img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.compulsoryf {
  color: blue;
  font-weight: bold;
}

.paytocontinuecontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}

.paytocontinue {
  display: block;
}

.continuetext {
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.buttontargetvalid {
  background-color: grey;
  color: black;
  cursor: unset;
}

.targetedSearchInfo {
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
}

.targetedSearchInfoTitle {
  font-size: 24px;
  font-weight: bold;
  color: black;
}

.targetedSearchInfoDesc {
  font-size: 18px;
  color: black;
  margin-bottom: 20px;
}

.targetedSearchInfoList li {
  color: #8f95a3;
  font-size: 16px;
}

.targetedSearchInfoList li span {
  font-weight: bold;
  color: black !important;
}

.targetedSearchInfoButton {
  padding: 10px;
  outline: none;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  margin-top: 50px;
  margin-left: 10px;
}

.targetedFormText {
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-left: 20px;
}

.cardPeopleContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  border: 1px solid black;
}

.cardPeople {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: initial;
  background-color: aliceblue;
}

.targetedSearchTry {
  padding: 10px;
  background-color: orange;
  color: white;
  border-radius: 10px;
  margin-left: 5px;
}

.cardPeopleImg {
  background-color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.cardPeopleImg img {
  width: 50%;
}

.targetedTry {
  margin-bottom: 50px;
  height: initial;
  position: relative;
}

.cardPeopleTop div {
  text-transform: uppercase;
}

.cardPeopleTop {
  background: aliceblue;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border-bottom: 2px solid cornflowerblue;
}

.targetSearchReset {
  cursor: pointer;
  background-color: red;
  color: white;
  padding: 10px 5px;
  position: absolute;
  right: 10px;
  top: 10px;
  outline: none;
  border: none;
}

.cardPeopleInfo {
  background-color: aliceblue;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
}

.cardPeopleInfo div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cardPeopleLink {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  cursor: pointer;
  background-color: aliceblue;
  color: #007bff;
  font-weight: bold;
}


.progressLazyapplyX {
  position: relative;
  height: 10px;
  width: 1110%;
  border: 10px solid #f4a261;
  border-radius: 15px;
}

.progressLazyapplyX .color {
  position: absolute;
  background-color: #ffffff;
  width: 0px;
  height: 10px;
  border-radius: 15px;
  animation: progressLazyapplyX 4s infinite linear;
}

@keyframes progressLazyapplyX {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
} ;
