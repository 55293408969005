.dashboardRight {
  display: block;
}

.dashboardRightMiddle {
  background-color: white;
}

.dashboardHeading {
  margin-top: 20px;
}

.emailAutomationLastSessionHeading {
  color: darkblue;
  font-weight: bold;
  font-size: 16px;
}

.emailAutomationLastSession {
  margin: 20px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 1px 1px 1.5px 1.5px #efefef, 1px 1px 1.5px 1.5px #efefef;
}

.emailAutomationLastSessionBlock {
  background-color: aliceblue;
  width: 100%;
  padding: 10px;
  margin: 10px 0px;
  cursor: pointer;
  border-radius: 10px;
}

.emailAutomationLastSessionBlockLink {
  margin-top: 20px;
  color: #007bff;
  cursor: pointer;
}

.emailAutomationLastSessionBlock span {
  color: green;
  font-weight: bold;
}

.maindashboardplus {
  font-size: 80px;
  color: whitesmoke;
  margin-top: 5px;
  margin-bottom: 5px;
}

.maindashboardbutton p {
  font-size: 20px;
  color: whitesmoke;
  margin: 0px;
  margin-right: 20px;
  padding: 0px;
}

.maindashboardbutton:hover .maindashboardplus {
  color: white;
}

.maindashboardbutton:hover .maindashboardbutton p {
  color: white;
}

.maindashboardtext {
  color: gray;
  margin: 10px;
  font-weight: normal !important;
  text-transform: inherit !important;
}
