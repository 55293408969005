.mainfaq {
  box-shadow: 1px 1px 1.5px 1.5px #efefef, 1px 1px 1.5px 1.5px #efefef;
  margin: 50px;
  margin-bottom: 100px;
}

.mainfaq .heading {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  position: relative;
  margin-bottom: 30px;
}

.mainfaq .initial {
  margin: 10px 25px 25px 25px;
  background-color: white;
  border-radius: 10px;
  padding: 20px 5px;
}

.mainfaq .main1 {
  margin: 0 50px;
  cursor: pointer;
  font-weight: bold;
  font-size: 25px;
  border-top: 1px solid #dcdcde;
}

.mainfaq .activemainq {
  color: #1975d2;
  text-align: left;
  font-size: 21px;
  font-weight: bold;
}

.mainfaq .defaultmainq {
  text-align: left;
  font-size: 21px;
  font-weight: bold;
  color: #8a8a8a;
}

.mainfaq .active {
  display: block;
  font-size: 15px;
  color: #3f071f;
  margin-top: 10px;
  font-weight: normal;
}

.mainfaq .default {
  display: none;
}

.mainfaq .mainfaq {
  padding: 0 170px;
  margin-top: 100px;
  margin-bottom: 50px;
}

i {
  color: #1975d2;
  font-size: 13px;
}

.mainfaq .col-10 {
  padding: 30px;
}

.mainfaq .col-2 {
  padding: 30px;
}

.mainfaq .mobileQ {
  margin-top: 10px;
  color: grey;
  font-weight: normal;
  font-size: 16px;
}

@media (max-width: 992px) {
  .mainfaq {
    padding: 4px;
    margin-top: 90px;
  }
  .mainfaq .heading {
    margin-bottom: 20px;
    font-size: 25px;
  }

  .mainfaq .main1 {
    margin: 0 10px;
  }
  .mainfaq .initial {
    margin: 10px;
    padding: 20px 5px;
  }
  .mainfaq .col-10 {
    padding: 18px;
    font-size: 16px;
  }

  .mainfaq .col-2 {
    padding: 10px;
  }

  .mainfaq .mobileQ {
    font-size: 15px;
  }
}
.mainfaq .mobfot {
  border-top: 2px solid #f0f0f0;
  margin: 0 auto;
  background-color: white;
  text-align: center;
  color: grey;
  line-height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .mainfaq {
    margin: 10px;
  }

  .mainfaq .col-10 {
    padding: 5px;
  }

  .mainfaq .main1{
    margin:0px;
  }
}
