#mainLazyapplyXAnalytics .email-thread-container {
  font-family: "Arial", sans-serif;
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#mainLazyapplyXAnalytics .email-thread-container h2 {
  background-color: #4a90e2;
  color: white;
  padding: 10px 15px;
  margin: 0;
  font-size: 20px;
}

#mainLazyapplyXAnalytics .email-original,
.email-reply {
  padding: 15px;
  border-bottom: 1px solid #eee;
}

#mainLazyapplyXAnalytics .email-original {
  background-color: #e8f4fc;
}

#mainLazyapplyXAnalytics .email-reply {
  background-color: #f9f9f9;
}

#mainLazyapplyXAnalytics .email-original h3,
.email-reply h3 {
  color: #333;
  margin-top: 0;
  font-size: 18px;
}

#mainLazyapplyXAnalytics .email-reply {
  font-size: 15px;
}

#mainLazyapplyXAnalytics ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#mainLazyapplyXAnalytics ul li {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
