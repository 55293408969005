.footerMain{
    display:grid;
    grid-template-columns:2fr 3fr 2fr;
    height:auto;
    justify-content:content;
    align-items:center;
    background-color: #F8F9FD;
    box-shadow: 0px 4px 2px #efefef, 0px -4px 2px #efefef;
}

.footerLeft,.footerRight,.footerCenter{
    display:flex;
    justify-content:center;
    align-items:center;
}

.footerCenter{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding:10px;
}

.footerHeading{
    font-weight:bold;
    font-size:20px;
}

.footerCenter p{
    color:grey;
    margin : 2px;
    padding:0px;
    font-size:15px;
}


 .footerRightLink{
    margin-right: 10px;
    color:grey
}