.purchaseToContinue {
  margin: 0 auto;
  border: none;
  text-align: center;
  margin: 20px 0px;
}

.purchaseToContinue .purchase {
  border: none;
  background-color: #007bff;
  color: white;
  font-size: bold;
  padding: 10px;
  border-radius: 10px;
}

.purchaseToContinue .getStarted {
  border: none;
  background-color: green;
  color: white;
  font-size: bold;
  padding: 10px;
  border-radius: 10px;
}

.createLazyapplyNewSession {
  background-color: #007bff;
  color: white;
  border: none;
  outline: none;
}

.gearButton {
  margin-right: 280px;
  background-color: #007bff;
  color: white;
  border: none;
  outline: none;
}

.gearbuttonnew {
  color: white;
  border: none;
  outline: none;
  font-size: 15px;
}

.checkanalytics {
  background-color: aquamarine;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;
}

.gearButtonShift {
  margin-right: 10px;
}

.feedbacklazyapplyx {
  margin-right: 400px;
}

.gearButton i {
  color: white !important;
}

.lazyapplyXSessions {
  background-color: aliceblue;
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.lazyapplyXSessions div {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  color: black;
}

.userTerms {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
}

.userTermsCheckbox {
  margin: 10px;
}

.mainemail {
  width:inherit;
  max-width: 400px;
  height: auto;
  border: 1px solid black;
  margin-bottom: 30px;
}

.mainemail1 {
  width: inherit;
  height: auto;
  max-width: 500px;
  border: 2px solid black;
  margin-bottom: 30px;
}

.targetedSearchInfoDesc1 {
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.givepermissions {
  font-weight: bold;
  font-size: 20px;
  margin: 20px;
}

.targetedSearchInfoList .col-3 {
  color: #007bff !important;
  font-weight: bold;
}

.targetedSearchInfoList .col-12 {
  color: #007bff ;
  font-weight: normal;
  margin-bottom: 20px;
}

.mainnavlazyapplyx {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
}

.mainnavlazyapplyx button {
  margin: 0 10px;
}

.gmailAnalyticsDivMainHeading {
  font-size: 20px;
  color: blue;
}

.gmailAnalyticsDiv {
  background-color: aliceblue;
  margin: 20px;
  margin-top: 60px;
  padding: 20px;
}

.gmailEmailDiv {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.gmailEmailBlock {
  display: flex;
  flex-direction: row;
  margin: 10px;
  color: black;
  font-weight: bold;
}

.gmailEmailBlock0 {
  margin: 0 10px;
}

.mainnavlazyapplyxbutton {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
}
