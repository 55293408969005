.navbarImg {
  width: 60px;
}

.navbarMainContainer {
  padding: 0;
  margin: 0;
  z-index: 1;
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
}

.navbarContainer {
  width: 100%;
  max-width: 100%;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.navbarChildContainer {
  max-width: 100%;
  margin: 0;
  padding: 5px;
}

.navbarChildContainerRight {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
}

.navbarChildContainerLeft {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.navbarLink {
  margin-right: 20px;
  position: relative;
  color: rgba(0, 0, 0, 0.5);
}

/* Google button */
.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 50%;
}
.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}
.login-with-google-btn:active {
  background-color: #eeeeee;
}
.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}
.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

/* login image */
.loginImage {
  height: 40px;
  width: 40px;
  margin-right: 0px;
}

/* logout button */
.logOut {
  padding: 5px 12px;
  background-color: white;
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
  color: #007bff;
  border: 2px solid;
  border-radius: 10px;
}

.navbarlinkActive::after {
  content: "";
  height: 2px;
  width: 50%;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
}

.navbarlinkActive {
  font-weight: bold;
}

.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255, 0.7);
  z-index: 999;
}

.loadingresume {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255, 0.7);
  z-index: 999;
}

.loading .boxLoading {
  width: 50px;
  height: 50px;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.loading .boxLoading::before {
  content: "";
  width: 50px;
  height: 5px;
  background: #000;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}
.loading .boxLoading::after {
  content: "";
  width: 50px;
  height: 50px;
  background: #b28fce;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  animation: animate 0.5s linear infinite;
}

.featureRequestBtn {
  background: #eef3ff;
  border: none;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  color: black;
  border: 0.5px solid black;
}

.featureRequestBtnInvites {
  background-color: orange;
  color: white;
  font-weight: bold;
  border: 0.5px solid orangered;
}

.openmeeting {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border: none;
}

.chromeExtensionUpdate {
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

.chromeExtensionStable {
  background-color: green;
  color: white;
  cursor: default !important;
}

.chromeExtensionUpgradeRequired {
  background-color: #007bff;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

.profile-dropdown{
  display: flex;
  justify-content: center;
  align-content: center;
}

.profile-dropdown #dropdown-basic {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.profile-dropdown .dropdown-toggle::after {
  font-size: 20px;
  color: #007bff;
}

.profile-dropdown .dropdown-menu {
  left: unset !important;
  right: 0 !important;
  float: right !important;
}

.profile-totaljobs {
  background-color: darkorange;
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 50%;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #007bff;
    -webkit-box-shadow: 0 0 3px blue;
  }
  50% {
    background-color: #007bff;
    -webkit-box-shadow: 0 0 15px blue;
  }
  100% {
    background-color: #007bff;
    -webkit-box-shadow: 0 0 3px blue;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #007bff;
    -moz-box-shadow: 0 0 3px blue;
  }
  50% {
    background-color: #007bff;
    -moz-box-shadow: 0 0 15px blue;
  }
  100% {
    background-color: #007bff;
    -moz-box-shadow: 0 0 3px blue;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #007bff;
    box-shadow: 0 0 3px blue;
  }
  50% {
    background-color: #007bff;
    box-shadow: 0 0 15px blue;
  }
  100% {
    background-color: #007bff;
    box-shadow: 0 0 3px blue;
  }
}

@keyframes glowing {
  0% {
    background-color: #007bff;
    box-shadow: 0 0 3px blue;
  }
  50% {
    background-color: #007bff;
    box-shadow: 0 0 15px blue;
  }
  100% {
    background-color: #007bff;
    box-shadow: 0 0 3px blue;
  }
}

@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes shadow {
  0%,
  100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}

.nav-icon3-container {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarMobileContainer{
  display: flex;
  width: 100%;
}

.navbarMobileMainText{
  background-color: white;
  font-size: 25px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarMobileAvataar{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  background-color: white;
}

.navbarMobileAvataar img{
  width: 70px;
}

#nav-icon3 {
  width: 60px;
  height: 45px;
  display: block;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  position: absolute;
  left: 15px;
  z-index: 10;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: cadetblue;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 18px;
}

#nav-icon3 span:nth-child(4) {
  top: 36px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

@media screen and (max-width: 768px) {
  #nav-icon3 span{
    width: 80%;
  }
}