.main-referral-container {
  /* background-color: #e5f3ff; */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 1.5px 1.5px #efefef, 1px 1px 1.5px 1.5px #efefef;
  margin: 20px;
}

.main-referral-modal-loading {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 10px;
  color: #007bff;
  font-weight: 400;
}

.main-referral-modal-loading-email {
  font-size: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 10px;
  color: #007bff;
  font-weight: 400;
}

.main-referral-modal-feedback-open-button {
  background-color: tomato;
  width: 100px;
  outline: none;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 40px;
  top: 5px;
  z-index: 10000;
}

.main-referral-modal-loading-email-body {
  font-size: 15px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: blue;
  font-weight: 400;
  margin-top: 20px;
}

.main-referral-container-modal {
  margin: 0px;
}

.main-referral-container-peoplelist {
  padding: 0px;
  margin: 0px;
  margin-top: 20px;
}

.main-referral-top {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 5px;
  background-color: aliceblue;
}

.main-referral-container-peoplelist .main-referral-top {
  background-color: ghostwhite;
}

.main-referral-top-first {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-referral-tags {
  display: inline-block;
}

.main-referral-tag-content {
  display: inline-block;
  font-size: 14px;
  background-color: aliceblue;
  margin: 10px 5px;
  padding: 2px 5px;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
}

.main-referral-tag-content-main {
  color: cornflowerblue;
  font-weight: bold;
}

.main-referral-tag-content-button {
  background: lavender;
  cursor: pointer;
}

.main-referral-company-name {
  font-size: 20px;
  color: black;
  margin-left: 10px;
  font-weight: 700;
}

.main-referral-company-domain {
  font-size: 12px;
  color: darkslategrey;
  margin-left: 10px;
  font-weight: 600;
}

.main-referral-company-name i {
  font-size: 25px;
  margin-left: 10px;
  cursor: pointer;
}

.main-referral-top-second {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  width: 100%;
  /* background-color: blue; */
  justify-content: center;
}

.main-referral-top-third {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
}

.main-referral-top-third button {
  font-weight: 500;
  width: 150px;
  outline: none;
  border: none;
  color: white;
  background: cadetblue;
  padding: 10px;
  border-radius: 5px;
}

.main-referral-company-otherdetails {
  margin-left: 10px;
  font-size: 14px;
  margin-top: 10px;
  font-weight: bold;
}

.main-referral-description {
  padding: 10px;
  font-size: 14px;
  color: dimgray;
  font-weight: 400;
}

.main-referral-applynow {
  display: flex;
  flex-direction: row;
}

.main-referral-domain {
  padding: 10px;
  background-color: aquamarine;
  border-radius: 10px;
  width: fit-content;
}

.referral-filters-search-box {
}

.main-referral-modal-header {
  border-bottom: none;
}

.main-referral-modal-header-cross {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.main-referral-send-email-modal-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-referral-send-email-modal-block div {
  margin: 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.main-referral-send-email-modal-block p {
  margin-top: 20px;
}

.main-referral-send-email-modal-block span {
  cursor: pointer;
  color: #007bff;
}

.main-referral-modal-header-cross i {
  font-size: 20px;
  cursor: pointer;
}

.main-referral-modal-body {
  padding: 10px;
  margin: 0px;
  margin-top: 20px;
}

.main-referral-modal-feedback-body p {
  font-size: 20px;
  font-weight: 400px;
  color: #007bff;
  margin-bottom: 0px;
}

.main-referral-modal-feedback-body textarea {
  margin: 10px 0px;
}

.main-referral-modal-feedback-body button {
  outline: none;
  border: none;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.main-referral-no-extension {
  height: auto;
  background-color: aliceblue;
  box-shadow: 1px 1px 1.5px 1.5px #efefef, 1px 1px 1.5px 1.5px #efefef;
  width: 100%;
  flex-direction: column;
  /* margin: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  padding: 50px 0px;
}

.main-referral-no-extension button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  outline: none;
  border: none;
  background-color: darkcyan;
  border-radius: 10px;
  color: white;
}

.main-referral-already-installed {
  margin-top: 20px;
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.main-referral-find-employees-init-button {
  background-color: #007bff;
  outline: none;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #007bff;
}

.main-referral-find-employees-init-button:hover {
  border: 2px solid darkblue;
}

.main-referral-find-employees-init-button i {
  color: white;
  font-size: 15px;
  margin-right: 10px;
}

.main-referral-no-extension img {
  width: 35px;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .main-referral-top {
    flex-direction: column;
  }

  .main-referral-top-second {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-referral-top-second div {
    margin: 5px 0px;
  }

  .main-referral-company-otherdetails {
    text-align: center;
  }

  .main-referral-top-first {
    padding: 10px 0px 0px 0px;
  }

  .main-referral-top-third {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 20px 0px;
  }

  .referral-fitlers-search-box-button-block button {
    width: 100px;
  }

  .referral-fitlers-select-input {
    margin: 5px;
  }

  .main-referral-container{
    padding: 5px;
    margin:10px 0px 10px 0px
  }

  .main-referral-no-extension{
    text-align: center;
    padding: 20px;
    font-size: 18px;
  }
}
