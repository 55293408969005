.dashboardRightTop {
  display: grid;
  height: 70%;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.dashboardRightTop .dashboardRightTopContent {
  text-align: center;
  margin: 10px;
  height: 90%;
  border-radius: 20px;
}

.dashboardHeading {
  display: grid;
  padding: 2px 10px;
  grid-template-columns: 1fr 1fr;
}

.dashboardHeadingLeft {
  display: grid;
}

.dashboardHeadingRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dashboardHeadingRight button {
  align-items: center;
  background: #1b1d1f;
  border-radius: 15px;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
  display: flex;
  height: 55px;
  justify-content: center;
  position: relative;
  width: 220px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  outline: none;
}

.dashboardHeadingRight .button1 {
  background: green;
  border: none;
  outline: none;
  margin-right: 10px;
}

.dashboardRightMiddle {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  background-color: #f8f9fd;
  border-radius: 20px;
  margin: 10px;
}

.dashboardRightMiddle .dashboardRightMiddleCard {
  width: auto;
  height: 480px;
  text-align: center;
  margin-left: 20px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
  position: relative;
}

.dashboardRightMiddleCard:hover {
  cursor: pointer;
}

.dashboardRightMiddleCard {
  margin: 10px;
  padding: 10px;
}

.dashboardRightMiddleCard {
  display: grid;
  grid-template-rows: auto;
  grid-template-rows: 1fr 2fr 1fr;
}

.jobdiv button {
  align-items: flex-end;
  justify-content: flex-end;
}

.jobdivimage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}

.indeedLogo {
  width: 100px;
  height: 100%;
}

.linkedinLogo {
  width: 40px;
  height: 40px;
}

.startNow {
  width: 100%;
  border-radius: 20px;
  border: none;
  padding: 10px;
  background-color: #56618a;
  color: white;
  font-size: 18px;
  text-align: center;
  box-shadow: 2px 2px 2px 2px #efefef, -2px -2px 2px 2px #efefef;
}

.startNowNew {
  background-color: gray;
  color: white;
  font-weight: bold;
}

.startNow:hover {
  color: white;
  background-color: #505d91;
  font-weight: bold;
}

.jobdivbutton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.jobdiv p {
  font-weight: bold;
  text-transform: uppercase;
}

.dashboardText {
  font-weight: bold;
  font-size: 18px;
  margin: 10px;
  margin-bottom: 5px;
}

.overviewText {
  font-size: 15px;
  margin: 10px;
  position: relative;
  width: 70px;
  margin-top: 5px;
}

.overviewText::after {
  content: "";
  height: 1px;
  width: 59%;
  position: absolute;
  top: 24px;
  left: 30%;
  transform: translateX(-50%);
  background-color: #007bff;
}

.dailyLimit {
  background-color: #eef3ff;
}

.payment {
  background-color: #fff9fb;
}

.totalJobs {
  background-color: #fefbec;
}

ul {
  margin-bottom: 0;
  height: auto;
}

li {
  text-align: left;
  list-style: none;
  margin-bottom: 6px;
  font-size: 13px;
  color: grey;
}

li:before {
  content: "\f105"; /* FontAwesome Unicode */
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  margin-left: -1.2em; /* same as padding-left set on li */
  width: 1.2em; /* same as padding-left set on li */
  font-weight: 900;
  color: #007bff;
}

li:last-child {
  margin-bottom: 0px;
}

.dailyLimitTop {
  display: grid;
  padding: 10px 0px;
  color: #44517d;
  grid-template-columns: 1fr 1fr;
}

.dailyLimitMiddle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #44517d;
}

.dailyLimitText {
  font-size: 25px;
  display: flex;
  padding-top: 10px;
  justify-content: flex-start;
  align-items: flex-end;
  margin-left: 15px;
}

.limitLeftText {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 10px;
  font-size: 20px;
  margin-right: 15px;
  color: #05897b;
  font-weight: bold;
}

.limitLeftText span {
  font-size: 17px;
  color: #44517d;
  font-weight: normal;
}

.progress {
  margin: 15px 15px;
  height: 7px;
  margin-bottom: 5px;
  background-color: black;
}

.progress-bar {
  background-color: #0957ff !important;
}

.dailyLimitBottom p {
  text-align: left;
  color: grey;
  margin-left: 15px;
  font-size: 14px;
  font-weight: bold;
}

.dailyLimitTopText {
  text-align: left;
  margin-left: 15px;
}

.limitLeftTopText {
  text-align: right;
  margin-right: 15px;
  font-size: 17px;
}

.totalJobsProgress .progress-bar {
  background-color: #ffb900 !important;
}

.planText {
  font-size: 17px;
  color: black;
  padding-bottom: 10px;
}

.planEnding {
  font-size: 17px;
  padding-bottom: 10px;
}

.votetitle {
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #05897b;
}

.jobdiv .vote {
  border-radius: 4px;
  border: 1.5px solid #05897b;
  color: #05897b;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  padding: 6px 10px;
  margin-bottom: 8px;
  background-color: white;
}

.jobdiv .vote:hover {
  background-color: #05897b;
  color: white;
}

.jobdivbutton p {
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
}

.jobdivbuttonreferral button {
  background-color: #008b8b;
}

.jobdivbuttonreferral button:hover {
  background-color: darkcyan;
}

.jobdiv .vote.voted {
  background-color: #05897b;
  color: white;
}

.othervote {
  width: 90% !important;
  margin: 0 auto !important;
}

.othervote.form-control {
  width: 90% !important;
  margin: 0 auto !important;
}

.betaimg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100px;
}

.zipLogo {
  width: 180px;
  height: 80%;
}

.cbLogo {
  width: 70px;
  height: 70px;
}
.founditLogo {
  width: 80px;
  height: 80px;
}
.simplyHiredLogo {
  width: 80px;
  height: 80px;
}
.monsterLogo {
  width: 80px;
  height: 80px;
}

.glassdoorLogo {
  width: 70px;
  height: 70px;
}

.diceLogo {
  width: 80px;
  height: 80%;
}

.watch_demo_button {
  display: flex;
  border: none;
  justify-content: center !important;
  align-items: center !important;
  outline: none;
  margin: 20px 0px 0px 0px;
  background-color: transparent;
}

.watch_demo_button_first {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 50px;
  padding: 7px 60px 7px 5px;
  border: 2px solid cadetblue;
  background-color: white;
  border-radius: 5px;
}

.watch_demo_button_second {
  outline: none;
  border: none;
  text-transform: uppercase;
  position: relative;
  width: 250px;
  height: 80px;
}

.watch_demo_button_second_main {
  background-color: cadetblue;
  border-radius: 5px;
  width: 105%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: 600;
  font-size: 20px;
  padding: 5px 15px;
  position: absolute;
  top: 50%;
  left: -5%;
  transform: translateY(-50%);
}

.watch_demo_button_first i {
  font-size: 40px;
  color: cadetblue;
}

.watch_demo_button_final {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  outline: none;
  background-color: #eee0c9;
  width: 100%;
  padding: 8px 0px;
  margin: 10px 0px;
  border-radius: 20px;
  border: 2px solid #dfc9af;
  font-size: 18px;
  color: black;
  font-weight: 800;
  text-transform: uppercase;
}

.watch_demo_button_final img {
  width: 30px;
  margin-right: 20px;
}

@media screen and (max-width: 1150px) {
  .dashboardRightMiddle {
    grid-template-columns: 1fr 1fr;
    overflow-y: scroll;
  }

  .planText {
    font-size: 16px;
    color: black;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .dashboardRight {
    margin-right: 0px;
  }

  .dashboardRightTop {
    grid-template-columns: 1fr;
  }

  .dailyLimitText {
    font-size: 16px;
  }

  .dailyLimitBottom {
    margin: 10px 0px;
  }

  .dashboardHeadingRight button {
    height: 55px;
    width: 180px;
  }
}

@media screen and (max-width: 576px) {
  .dashboardRightMiddle {
    display: block;
  }

  .dashboardRightMiddleCard {
    margin-bottom: 20px;
  }
}
