/* toggle start */
.container-toggle {
  margin: 0 auto;
  display: block;
}

.up-in-toggle {
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.up-in-toggle input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.up-in-toggle input:checked + label {
  background: black;
  color: #faba3c;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.13);
}
.up-in-toggle label {
  display: inline-block;
  width: 60px;
  background-color: #495a6f;
  color: #a5adb7;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  padding: 6px 6px;
  transition: all 0.1s ease-in-out;
  padding: 20px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.up-in-toggle label:hover {
  cursor: pointer;
}

.up-in-toggle label:first-of-type {
  border-radius: 15.5px 0 0 15.5px;
}

.up-in-toggle label:last-of-type {
  border-radius: 0 15.5px 15.5px 0;
}
/* toggle end */
