.orginasation_details_block {
  box-shadow: 0px 1px 1px 2px #efefef, 0px 1px 1px 2px #efefef;
  margin: 20px;
  padding: 20px;
}

.orginasation_details_heading {
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin-top:20px;
}

.orginasation_details_div {
  margin: 10px 0px;
}

.orginasation_details_name {
  font-size: 16px;
  font-weight: 500;
}

.organisation_role{
    color:darkcyan;
    font-weight: bold;
    margin-left: 10px;

}

.organisation_li{
    font-size: 18px;
    margin:5px 0px;
    font-weight: normal;
}

.orginasation_details_current_users{
    margin-bottom: 20px;
}

.orginasation_details_current_users_link{
    color:#007bff;
    text-decoration: underline;
    cursor: pointer;
}

.orginasation_details_current_users_heading{
    font-size: 18px;
    color:darkblue;
    text-decoration: underline;
    margin:10px 0px;
}

.orginasation_details_address {
  font-size: 16px;
  font-weight: 500;
}

.orginasation_details_contact {
  font-size: 16px;
  font-weight: 500;
}

.orginasation_details_plan {
  color: darkcyan;
  margin: 10px 0px;
}
