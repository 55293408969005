.filtershelp_main{
    background-color:#1B1D21;
    padding:10px;
    box-shadow: 0px 10px 20px #efefef, 0px -10px 20px #efefef;
    border-radius:10px 0 0 10px;
}

.filtershelp_header i{
    font-size: 20px;
    color:#FFD700;
}

.filtershelp_header{
    color:white;
    margin-bottom: 10px;
    font-weight: bold
}

.filtershelp_header .fa-times{
    color: grey !important;
    cursor: pointer!important;
}

.filtershelp_body{
    color:#999999;
    font-size:17px;
}

.filtershelp_body p{
    margin-top:15px;
    color:#8DE1F0;

}

.dontshow{
    background-color:#8DE1F0;
    color:black;
    border-radius: 5px;
    margin-top:0px;
    cursor:pointer;
    border:none;
    margin-bottom: 5px;
    z-index: 10000;
}