.extensionInstallMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.extensoinInstallFirstDivTitle {
  font-size: 25px;
  font-weight: 800;
  color: #007bff;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

.extensoinInstallPoints {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.extensoinInstallPoints img {
  width: 100%;
  border: 2px solid black;
  border-radius: 10px;
}

.extensoinInstallMainImgDiv {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 80%;
}

.extensoinInstallFirstDivTitle {
  text-decoration: underline;
  cursor: pointer;
}

.extensoinInstallMainImgDivFirst {
  display: flex;
  justify-content: center;
  align-items: center;
}

.extensoinInstallMainImgDivSecond {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.extensoinInstallMainImgDivSecond img {
  outline: none;
  border: none;
}

.extensoinInstallPoints .jobdiv li {
  margin: 20px;
  font-size: 16px;
  color: black;
  font-weight: 600;
}

.extensoinInstallOr {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: white;
  font-weight: bold;
  margin: 20px;
  background-color: cornflowerblue;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 40px;
}

.extensoinInstallSecondDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.extensoinInstallSecondDiv p {
  display: block;
  color: black;
  font-weight: 500;
}

.extensoinInstallSecondDiv .watch_demo_button_final {
  width: auto;
  padding: 10px 20px;
}
