.privacyContainer {
  font-size: 20px;
  margin: 20px;
  padding: 20px;
  display: block;
  position: relative;
}

.privacyHeading {
  text-decoration: underline;
  color: #007bff;
  font-weight: bold;
}

.privacyDescriptionHeadings {
  color: black;
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
}

.privacyDescriptionData {
  font-weight: normal;
  font-size: 16px;
}
