.mainResumeViewer {
  margin:0 20px;
}

.mainResumeViewerUploadButton {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  padding: 10px;
  border: none;
  outline: none;
  width: fit-content;
  border-radius: 10px;
}
