.loginmainblurred {
  position: absolute;
  filter: blur(2px);
  pointer-events: none;
}

.loginmainblock {
  position: absolute;
  z-index: 1;
}

.loginmainblock .loginGrid {
  position: fixed;
  z-index: 100000;
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0.3);
}

.loginmainblock .loginGridChild {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg)
    translate(-50%, -50%);
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.8);
}

.loginmainblock .loginMainPopup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.loginmainblock .loginMainPopupTitle {
  font-weight: 600;
  font-size: 25px;
  color: black;
  text-align: center;
}

.loginmainblock .loginGridChild .jobdiv {
  padding: 0px;
  margin: 15px 0px;
}

.loginmainblock .loginGridChildContent {
  margin-bottom: 20px;
}

.loginmainblock .loginGridChild .jobdiv ul {
  padding: 5px;
  margin: 0px;
}

.loginmainblock .loginGridChild .jobdiv li {
  font-size: 16px;
  margin: 10px;
  color: darkblue;
}
