.skeleton-container {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-gap: 20px;
  width: 90%;
  margin-top: 50px;
  margin: 50px auto;
}

.skeleton-card {
  border: 1px solid #ddd;
  height: 200px;
  background-color: #fff;
  border-radius: 10px;
  animation: loading-animation 1s ease-in-out infinite;
}

.skeleton-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ddd;
  margin-bottom: 10px;
}

.skeleton-line {
  width: 100%;
  height: 12px;
  background-color: #ddd;
  margin-bottom: 8px;
}

.skeleton-post {
  height: 400px;
  background-color: #ddd;
  border-radius: 10px;
  animation: loading-animation 1s ease-in-out infinite;
}

.skeleton-post {
  height: 400px;
  background-color: #f5f5f5;
  border-radius: 10px;
  animation: loading-animation 1s ease-in-out infinite;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.skeleton-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ddd;
  margin: 0 auto;
  margin-bottom: 20px;
}

.skeleton-line {
  width: 100%;
  height: 12px;
  background-color: #ddd;
  margin-bottom: 10px;
}

.skeleton-button {
  width: 100%;
  height: 40px;
  background-color: #ddd;
  border: none;
  border-radius: 4px;
  margin-top: 20px;
}

.main-referral-skeleton-post {
  height: auto !important;
}

@keyframes loading-animation {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}
