.main-referral-not-found{
    height: auto;
    background-color: aliceblue;
    box-shadow: 1px 1px 1.5px 1.5px #efefef, 1px 1px 1.5px 1.5px #efefef;
    width: 100%;
    flex-direction: column;
    /* margin: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    padding:50px 0px
}

.main-referral-not-found img{
    width:150px;
}