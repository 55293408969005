.starchecked {
	color: #f1c402;
	font-size: 25px;
	cursor: pointer;
}

.starnotchecked {
	color: #878c9a;
	font-size: 25px;
	cursor: pointer;
}

.feedbackform_rating {
	display: flex;
	justify-content: center;
}

.starcheckedhover {
	color: #c09c01;
}

.feedbackform_schedule{
	margin:10px;
}

.feedbackform_meeting{
	margin-top: 15px;
	color:#007bff;
	font-weight: bold;
	cursor: pointer;
}

.feedbackform {
	width: 100%;
	padding: 10px;
	border-radius: 10px;
	box-shadow: 0px 1px 1px 2px #efefef, 0px 1px 1px 2px #efefef;
	background-color: #def2fd;
}

.feedbackform_submitbutton {
	display: block;
	padding: 10px;
	margin: 20px auto;
	font-weight: bold;
	color: #007bff;
	border-radius: 10px;
	border: 1px solid #007bff;
	background-color: white;
}

.feedbackform_submitbutton_referal{
    display: block;
	padding: 2px 5px;
	margin: 10px auto 0 auto;
    font-size: 15px;
	color: green;
	border-radius: 5px;
	border: 1px solid green;
	background-color: white;
}

.feedbackform_submitbutton_referal:hover {
	background-color: green;
	color: White;
}

.feedbackform_submitbutton:hover {
	background-color: #007bff;
	color: White;
}

.feedbackform_desc {
	margin: 10px 0px;
}

.feedbackform_desc {
	text-align: center !important;
}

.feedbackform_header {
	color: #007bff;
	font-weight: bold;
	font-weight: 16px;
	text-align: center;
}
.feedbackform_header_number {
	color: #3b3b3c;
	font-size: 22px;
	text-align: center;
}

.feedbackform_rating {
	margin: 10px 0px;
	padding: 10px 0px;
}

.feedbackform_errorpara {
	color: red;
	text-align: center;
	font-size: 15px;
	margin: 5px 0px;
	font-weight: bold;
}

.feedbackform_submitbutton_success {
	background-color: grey;
	color: black;
}
.feedbackform_submitbutton_success:hover {
	background-color: grey;
	color: black;
}

.feedback_ratingnumber {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 10px;
	color: white;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
	margin: 0 5px;
}

.ratingnumber_red {
	background-color: #f74f5f;
}

.ratingnumber_red:hover {
	background-color: red;
}

.ratingnumber_yellow {
	background-color: #eaa214;
}

.ratingnumber_yellow:hover {
	background-color: orange;
}

.ratingnumber_green {
	background-color: #61c594;
}

.ratingnumber_green:hover {
	background-color: green;
}

.feedbackform_hide {
	position: absolute;
	right: 5px;
	top: 5px;
	cursor: pointer;
	color: grey;
	font-weight: bold;
	opacity: 0.7;
}

.feedbackform_referal {
	position: fixed !important;
	z-index: 10000 !important;
	bottom: 0px !important;
    padding: 0px!important;
    padding-top :10px !important;;
}

.feedbackform_desc_referal .form-control {
	width: 60%!important;
	margin: 0 auto!important;
    border-radius: 15px!important;
}
