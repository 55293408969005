/* plan switcher */
.plan-switcher {
  display: flex;
  align-items: center;
  font-size: 30px;
  color: black;
  user-select: none;
  margin: 0px 0px 60px 0px;
  justify-content: center;
}

.slider {
  width: 150px;
  height: 50px;
  border-radius: 25px;
  background-color: #e0e0e0;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 15px;
}

.slider.yearly {
  background-color: #007bff;
}

.circle {
  width: 48px;
  height: 48px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.slider.yearly .circle {
  left: 101px;
}

.plan-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
}

.plan-labels span {
  color: #7f7f7f;
}

.slider.yearly + .plan-labels span:last-child {
  color: #007bff;
}

.slider.monthly + .plan-labels span:first-child {
  color: #007bff;
}

.save-label {
  font-size: 18px;
  color: #ff6347;
  margin-left: 5px;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .plan-switcher {
    font-size: 15px;
  }

  .slider {
    width: 100px;
  }

  .circle {
    width: 48px;
    height: 48px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: left 0.3s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .slider.yearly .circle {
    left: 55px;
  }
}
