.stepswithcut {
    display: flex;
    background-color: white;
    justify-content: column;
    width: fit-content;
    padding: 15px 0px;
    border-bottom: 1px solid rgb(226 232 240);
  }
  
  .stepswithcut:hover {
    background-color: #f9fbfd
  }
  
  .stepswithcut_front {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .stepswithcut_circle {
    width: 40px;
    height: 40px;
    border: 1px solid #007bff;
    position: relative;
    border-radius: 50%;
    border-color: rgb(226 232 240);
  }
  
  .stepswithcut_help {
    font-size: 12px;
    color: #007bff;
    margin-top: 15px;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .stepswithcut_checkmark_circle {
    background-color: #14b8a6;
  }
  
  .stepswithcut_checkmark_circle i {
    color: white !important;
  }
  
  .stepswithcut_checkmark_heading {
    background-color: #14b8a6;
    height: 1.5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .stepswithcut_heading_checkmark {
    color: #14b8a6!important;
  }
  
  .stepswithcut_content {
    display: flex;
    cursor: pointer;
    width: 500px;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .stepswithcut_heading {
    color: black;
    font-weight: bold;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.5rem;
    position: relative;
  }
  
  .stepswithcut_paragraph {
    color: #475569;
    font-size: 0.9rem;
    line-height: 1.25rem;
  }
  
  .stepswithcut_circle i {
    position: absolute;
    top: 50%;
    color: rgb(71 85 105);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .stepswithcut_back .stepswithcut_circle:hover {
    cursor: pointer;
  }
  