.coverletteroutput {
  display: flex;
  width: 100%;
  background-repeat: repeat;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
  padding-bottom: 34px;
  width: 100%;
  overflow: hidden;
  background-color: whitesmoke;
  color: black;
  font-family: Arial, sans-serif;
  font-weight: medium;
  font-size: 22px;
  resize: none;
  line-height: 40px;
}

.EmailTemplate{
  font-size: 20px;
  text-align: center;
  color:black;
  margin:20px 5px;
}

.EmailTemplate span{
  color:#007bff;
  font-weight: bold;
}

.coverletteroutput.emailTemplateBody{
  width: 100%;
  line-height: 20px;
  font-size: 16px;
  background: aliceblue;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .coverletteroutput {
    padding: 20px;
    font-size: 18px;
  }
}

.coverletterenterdetails {
  color: white;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.featureheadingcover {
  color: white;
  text-decoration: underline;
  margin-bottom: 20px;
}

.formcoverletter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coverletterbox {
  padding: 50px 0px;
  margin:0 50px;
  /* background-color: black; */
}

.coverletterlabel {
  color: #23b88e;
  font-size: 18px;
}

.coverletterinputblock {
  margin-top: 20px;
}

.optionalcover {
  font-size: 16px;
  background-color: brown;
  color: white;
  border: none;
  outline: none;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.resetcover {
  background-color: red;
  position: absolute;
  padding: 10px 20px;
  font-size: 15px;
  right: 0px;
  color: white;
  /* top: 0px; */
  outline: none;
  border: none;
}

.copycover {
  background-color: white;
  right: 80px;
  font-size: 15px;
  padding: 10px 20px;
  color: black;
}

.tryagain {
  background-color: #007bff;
  right: 130px;
  font-size: 15px;
  padding: 10px 20px;
  color: white;
}

.loadingcover {
  margin: "50px";
}

.covergeneratorbutton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.covergeneratorbutton button {
  background-color: #23b88e;
  color: white;
  padding: 20px;
  font-size: 22px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

.formcover {
  width: 100%;
  max-width: auto;
  margin: 10px 100px;
}

@media screen and (max-width: 600px) {
  .formcover {
    width: 100%;
    max-width: auto;
    margin: 10px 20px;
  }
}
