.mainreview {
    background-color: white;
    width: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: auto;
    margin-bottom: 20px;
    border:2px solid #007bff
  }
  
  .mainreview.mainreviewheight {
    height: auto;
  }
  
  .showmore {
    color: #007bff;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
  }
  
  .mainreview-name {
    margin-left: 10px;
  }
  
  .mainreview-nametext {
    color: black;
    font-weight: bold;
  }
  
  .mainreview-desc {
    color: #191919;
  }
  
  .mainreview-time {
    color: #86869b;
  }
  
  .mainreview-title {
    color: black;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .mainreview-noreviews {
    display: flex;
    justify-content: row;
    color: #86869b;
  }
  
  .mainreview-avataar {
    background-color: #e2effb;
    width: 55px;
    height: 55px;
    color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mainreviews {
    margin: 25px 100px;
  }
  
  .mainreview-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .featureHeading{
    font-size: 30px;
    color: black;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .mainreview-location {
    display: flex;
    align-items: end;
    margin-left: 15px;
  }
  
  .mainreview-location img {
    height: 20px;
    margin-bottom: 2px;
  }
  
  .mainreview-border {
    border-bottom: 0.5px solid #dcdce6;
    margin-bottom: 10px;
  }
  
  .mainreview-second-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  
  .mainreview-second-container div:first-child {
    flex-grow: 1;
    flex-basis: 0;
  }
  
  .mainreview-rating img {
    width: 120px;
  }
  
  
  .mainreviews-heading{
    color:white;
    font-size: 26px;
    margin-bottom: 25px;
    text-align: center;
  }
  
  @media screen and (max-width: 450px) {
    .mainreviews {
      margin: 25px 5px;
    }
  
    .mainreview {
      height: auto;
    }
  }
  
  @media screen and (min-width: 992px) {
    .mainreview {
      height: auto;
      min-height: 330px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .mainreview {
      height: auto;
      min-height: 270px;
    }
  }
  
  
  @media screen and (min-width: 450px) and (max-width: 992px) {
    .mainreviews {
      margin: 25px 5px;
    }
  
    .mainreview {
      height: auto;
    }
  }
  