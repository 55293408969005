.dashboard {
  display: flex;
  flex-direction: row;
  width: 100vw;
  /* height: calc(100vh - 60px); */
}

.dashboardLeft {
  background-color: #f7f7f7;
  width: 280px;
  max-width: 400px;
}

.dashboardRight {
  display: grid;
  width: 100vw;
  margin-right: 20px;
  grid-template-rows: 1fr 2fr;
  height: initial;
}

@media screen and (max-width: 1150px) {
  .dashboardRight {
    display: grid;
    width: 100vw;
    grid-template-rows: 1fr 2fr;
  }
}

.feedback button:nth-child(1) {
  right: 1% !important;
}

.feedback div:nth-child(1) {
  right: 1% !important;
}
