.applieddash {
  height: fit-content;
  padding-bottom: 50px;
  margin-top: 5px;
}

.applieddashTop {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #f8f9fd;
  height: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.applieddashLeft {
  border-right: 1px solid black;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.applieddashRight {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.applieddashactive {
  background-color: lavender;
  font-weight: bold;
  color: #44517d;
  border: 1px solid black;
}

.applieddashMiddle {
  padding: 10px;
}

.applieddashsession {
}

.sessiondiv {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #eef3ff;
  margin-bottom: 10px;
}

.sessiontop {
  display: grid;
  height: 50px;
  grid-template-columns: 1fr 1fr;
}

.sessionRight,
.sessionRight1 {
  text-align: right;
}

.sessionmiddle {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sessionRight1 {
  font-size: 20px;
  color: #44517d;
  font-weight: normal;
}

.sessionLeft1 {
  font-size: 20px;
  color: black;
  font-weight: bold;
}

.sessionbottom {
  margin-top: 10px;
}

.sessionbottom span {
  color: grey;
}

.nosessiondata {
  width: 100%;
  height: 150px;
  margin: 5px 30px;
  display: flex;
  font-size: 20px;
  color: #44517d;
  background-color: #f8f9fd;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
}

.indeedlinks {
  background-color: #f7f7f7;
  height: calc(100vh - 250px);
  margin: 10px;
  overflow-y: scroll;
}

.linkedinlinks {
  background-color: #f7f7f7;
  height: calc(100vh - 250px);
  margin: 10px;
  overflow-y: scroll;
}

.sessionlinkshead {
  margin-left: 10px;
  color: #44517d;
}

.linkedinAppliedDetails {
  background-color: #f8f9fd;
  border-radius: 5px;
  padding: 10px;
  padding-top: 20px;
  font-size: 15px;
  color: #44517d;
  margin: 7px 2px;
  border: 0.5px solid;
  position: relative;
}

.linkedinAppliedDetailsIndex {
  position: absolute;
  top: 2px;
  left: 3px;
  font-size: 15px;
  color: black;
}

.sessionPlatformName {
  background: steelblue;
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border: none;
  outline: none;
}

.linkedinAppliedDetailsSeperate {
  background-color: #fff9fb;
  color: black;
  border: none;
  outline: none;
  padding: 20px;
  height: 100%;
}

.linkedinAppliedDetailsSeperate div {
  margin: 10px 0px;
}

.linkedinAppliedDetailsSeperate span {
  color: #02897b;
  font-weight: bold;
}

.linkedinAppliedDetailsSeperateTop {
  padding: 20px;
  background: aliceblue;
}

.linkedinAppliedDetailsSeperateTop .col-4 {
  display: flex;
  justify-content: end;
  align-items: center;
}

.linkedinAppliedDetailsSeperateTop .sessionLeft {
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.notfound .col-12 {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
}

.buttonGoBackCustom {
  position: absolute;
  right: 10px;
  top: -10px;
  border: 1px solid;
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
}

.buttonGoBackCustom i {
  margin-right: 5px;
  color: black;
}

.buttonGoBackCustom:hover {
  background-color: #007bff;
  color: white;
}

.buttonGoBackCustom:hover i {
  color: white;
}

.dashboardRightPlatform {
  cursor: pointer;
}

.appliedJobsTable {
  display: block;
  padding: 20px;
  max-width: 100%;
  overflow-x: scroll;
}

.appliedJobsTable .tableWrap {
  display: block;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  border-bottom: 1px solid black;
}
.appliedJobsTable table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid black;
}
.appliedJobsTable table tr:last-child td {
  border-bottom: 0;
}
.appliedJobsTable table th,
.appliedJobsTable table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  width: 1%;
}
.appliedJobsTable table th.collapse,
.appliedJobsTable table td.collapse {
  width: 0.0000000001%;
}
.appliedJobsTable table th:last-child,
.appliedJobsTable table td:last-child {
  border-right: 0;
}

.appliedJobsTable .pagination {
  padding: 0.5rem;
}

.appliedJobsTable thead th {
  text-align: center;
}
