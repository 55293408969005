.main-referral-pagination-block {
  display: flex;
  flex-direction: row;
}

.main-referral-pagination-block button {
  background-color: #007bff;
  width: 150px;
  padding: 10px;
  border: none;
  color: white;
  outline: none;
  border-radius: 5px;
}

.main-referral-pagination-second {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
  margin-right: 20px;
}

.main-referral-pagination-first {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  margin-left: 20px;
}
