.main-referral-block{
    padding-bottom: 100px;
    margin:0 50px;
}

@media screen and (max-width: 768px) {
    .main-referral-block {
      margin: 0 10px;
      padding:0px;
    }
  }
  