.loginGrid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f8f9fd;
}

.RSPBprogressBar {
  margin: 20px;
}

.loginGridChild {
  background-color: #dbe6fd;
  height: auto;
  width: 430px;
  justify-content: center;
  padding: 10px;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  display: block;
  margin: 0 auto;
}

.loginGridChildContent {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.loginGridChildContent img {
  width: 350px;
  height: 350px;
}

.googleLoginButton button {
  padding: 15px 40px 15px 80px;
  color: #2b3337;
  font-size: 16px;
  background-size: 25px;
  background-position: 33px 50%;
}

.signup {
  color: #47597e;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
  padding-bottom: 25px;
}

.form12 {
  margin: 5px;
  padding: 5px;
}

.fromwherecolor {
  background-color: #43ae45;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  margin: 10px auto;
  border-radius: 10px;
  width: 95%;
  display: block;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .loginGrid {
    padding: 50px;
  }
  
  .loginGridChildContent img {
    width: 200px;
    height: 200px;
  }
}
