.linkedinSubmitButton {
  display: flex;
  margin: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  color: #007bff;
  font-weight: bold;
  border: 1px solid #007bff;
  border-radius: 10px;
  text-transform: uppercase;
}

.linkedinSubmitButton:hover {
  background-color: #007bff;
  color: white;
}

.linkedinSubmitButton i {
  margin-left: 5px;
}

.jobDetails {
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
  display: flex;
  background-color: #f8f9fd;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  justify-content: center;
}

.jobDEtailsTop0 {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
  width: 100%;
  background-color: #f0f4ff;
  border: 1px solid #007bff;
}

.jobDetailsTop {
  display: grid;
  width: 100%;
  padding: 5px;
  grid-template-rows: 1fr 0.5fr;
}

.jobDetailsBottom {
  width: 100%;
  padding: 0 10px;
  padding-bottom: 10px;
}

.jobDetailsMiddle div {
  padding: 2px;
}

.currentlyWorkHere0 {
  padding: 5px;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: grey;
}

.currentlyWorkHere1 {
  padding: 5px;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: grey;
}

.jobDetailsTopChild1 {
  display: grid;
  grid-template-columns: 1.2fr 0.7fr;
}

.jobDetailsTopChild0 div {
  padding: 2px;
}

.jobDetailsMiddle {
  width: 100%;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.jobDetailsSkill {
  display: grid;
  width: 100%;
  padding: 2px;
  grid-template-columns: 0.6fr 0.3fr 0.2fr;
}

.jobDetailsSkill div {
  padding: 2px;
}
.jobDetailsSkillTop {
  width: 100%;
  margin: 0;
  background-color: #f8f9fd;
}

.jobDetailsSkillTop p {
  font-size: 15px;
  color: grey;
}

.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdfbeb;
}

.addButton button {
  outline: none;
  border-radius: 5px;
  border: none;
  padding: 8px;
  border: 1px solid #007bff;
}

.addButton button:hover {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.languagesYouKnow .jobDEtailsTop0 {
  border: none;
}

.languagesYouKnow .jobDetailsSkillTop {
  background-color: white;
}

.languagesYouKnow .jobDetailsSkill {
  background-color: white;
}

.languagesYouKnow .addButton {
  background-color: white;
}

.languagesYouKnow .addButton button {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 18px;
}

.skillSpan {
  background-color: #f7f7f7;
  border: 1px solid grey;
  color: black;
  padding: 7px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 15px;
}

.jobDetailsSkillValue {
  margin-top: 20px;
  height: 450px;
  overflow-y: auto;
}

.languagesYouKnow .jobDetailsSkillValue {
  height: 100px;
}

.submitForm {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #fff9fb; */
  margin: 0 15px;
  margin-top: 20px;
}

.form-label {
  font-size: 15px;
  color: grey;
}

.makeSure {
  position: absolute;
  top: 7px;
  left: 10px;
  font-size: 16px;
  color: black;
  font-weight: bold;
}

.makeSure a {
  color: #007bff;
}

.v2resume {
  background-color: #f8f9fd;
  border-radius: 20px;
  margin: 10px;
  height: auto;
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v2resumeAlreadyPresent {
  border-radius: 20px;
  height: auto;
  min-height: 300px;
  padding: 20px;
  width: 100%;
  margin-top: 100px;
}

.v2UserInvites {
  border-radius: 20px;
  height: auto;
  min-height: 300px;
  padding: 20px;
  width: 100%;
  margin-top: 10px;
}

.v2UserInvites .col-12 {
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px 2px lightgrey, 0px 2px 4px 2px #efefef;
}

.v2UserInvites .col-8 {
  display: flex;
  align-items: center;
}

.v2resumeSelect {
  border-radius: 20px;
  height: auto;
  padding: 20px;
  width: 100%;
  padding-bottom: 0px;
}

.createfirstresume {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  color: #007bff;
  font-weight: bold;
  border: 1px solid #007bff;
  border-radius: 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.resumeblock {
  height: auto;
  text-align: center;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 2px 4px 2px #efefef, 0px 2px 4px 2px #efefef;
  position: relative;
  margin-bottom: 20px;
}

.resumeblockimg {
  display: flex;
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
}

.resumeblockimg img {
  width: 50px;
  height: 50px;
}

.resumeblocktext {
  font-size: 15px;
  font-weight: bold;
  color: #212429;
  margin: 5px;
}

.resumeblockedit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.editresumeheading {
  font-size: 24px;
  font-weight: bold;
  color: black;
  margin: 10px;
}

.editresumeheading1 {
  font-size: 20px;
  color: darkcyan;
  margin-left: 20px;
  margin-top: 40px;
}

.resumeblockedit button {
  background-color: #56618a;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  margin: 10px;
}

.resumeblockstatus {
  padding: 3.5px;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 9px;
  color: white;
}

.v2userinviteemail {
  color: #007bff;
  font-size: 18px;
}

.v2userinviteaccepted {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  background-color: green;
  color: white;
  font-weight: bold;
}

.v2userinviterejected {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  background-color: orange;
  color: white;
  font-weight: bold;
}

.v2userinviteemail span {
  font-style: italic;
  font-weight: bold;
}

.statuscompleted {
  background-color: green;
}

.statuspending {
  background-color: orange;
}

.userinvitessecondheading {
  font-size: 20px;
  font-weight: bold;
  color: #007bdf;
}

.resumeEditOptions .dropdown {
  position: absolute;
  right: 0;
}

.resumeEditOptions .dropdown-item {
  background-color: aliceblue;
  border: 0.5px solid gray;
}

.resumeEditOptions .show > .btn-success.dropdown-toggle {
  background-color: aliceblue;
  color: blue;
  font-weight: bold;
  border: 1px solid lightblue;
}

.resumeEditOptions .show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.resumeEditOptions .btn-success {
  background-color: aliceblue;
  color: black;
  font-weight: bold;
  border: 1px solid lightblue;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  user-select: none;
  align-items: stretch; /* Make the children stretch to occupy full height */
  max-width: calc(100% - 32px);
}

.checkbox-group-parent {
  position: relative;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  &:before {
    content: "";
    position: absolute;
    z-index: 10;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &:hover {
    border: 2px solid #7b99e8;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 7rem; /* the initial width */
  width: auto; /* allows the container to grow */
  max-width: 100%; /* ensures the container doesn't grow beyond its parent */
  height: 100%;
  border-radius: 0.5rem;
  border: 2px solid #fff;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(#000, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  /* &:hover {
    border-color: #2260ff;
    border: 2px solid #b5bfd9;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  } */
}

.checkbox-input {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:checked + .checkbox-group-parent {
    border-color: #2260ff;
    box-shadow: 0 5px 10px rgba(#000, 0.1);
    color: #2260ff;

    &:before {
      transform: scale(1);
      opacity: 1;
      background-color: #2260ff;
      border-color: #2260ff;
    }

    .checkbox-icon,
    .checkbox-label {
      color: #2260ff;
    }
  }

  &:focus + .checkbox-group-parent {
    border-color: #2260ff;
    box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #b5c9fc;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
  svg {
    width: 3rem;
    height: 3rem;
  }
}

.checkbox-label {
  white-space: normal;
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
  overflow: hidden; /* hides the overflowing content */
  text-overflow: ellipsis; /* shows '...' where the text is clipped */
  max-width: 100%; /* prevents the label from expanding beyond its container */
  word-wrap: break-word;
}

.custom-modal-width {
  max-width: 40%;
}
.custom-modal-width2 {
  max-width: 75%;
}

.custom-modal-width3 {
  max-width: 60%;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 50px 50px 50px;
}

.checkbox-wrapper {
  display: flex;
  /* flex: 1; */
  flex-direction: column;
}

.checkbox {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bufferDiv {
  margin: 5px 0px 5px 0px;
}
.bufferDiv label {
  margin-left: 10px;
}

.featuresDiv0 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 100px;
  margin-top: 50px;
}
.featuresDiv1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 50px;
}

.featureHeading {
  font-size: 40px;
  line-height: 1.6;
  font-weight: bold;
  color: white;
  margin-bottom: 50px;
  margin-top: 50px;
  font-family: "Rubik", sans-serif;
  text-align: center;
}

.featuresChild0 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.featuresChild0Content {
  width: 80%;
  height: auto;
  padding-bottom: 10px;
  border-radius: 5px;
  background-color: #daebf8;
}

.featuresChild0Content:hover {
  border-style: none;
  box-shadow: 0 0 24px 4px hsl(0deg 0% 60% / 16%);
  opacity: 1;
}

/* .featuresChild0Content:hover .featurepoint {
  color: black;
  background-color: #f2fa8c;
} */

/* .featuresChild0Content:hover .featuretitle {
  color: white;
} */

.featuresChild1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
}

.featuresChild1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
}

.featuresImageX {
  border-radius: 20px;
  box-shadow: 0px 5px 5px 5px grey, 0px 5px 5px 5px grey;
  margin: 2px 0px 2px 0px;
  position: relative !important;
}

.featuresImage2 {
  width: 100%;
  height: 90% !important;
}

.featurepoint {
  line-height: 40px;
  border-radius: 50%;
  font-size: 13.5px;
  text-align: center;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  background-color: #2161fd;
  font-weight: bold;
  color: white;
  margin-left: 10px;
}

.featuresChild0Contenttop {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 10px;
}

.featuresChild0ContentBottom {
  margin-left: 20px;
  margin-top: 10px;
  color: #999999;
}

.featuresChild0ContentBottom p {
  padding-right: 15px;
}

.featuretitle {
  margin-left: 20px;
  color: #0000007c;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.featuresChild0Content {
  display: grid;
}

.featuresAddToChrome img {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.featuresAddToChrome {
  border-radius: 15px;
  border: none;
  padding: 10px 15px;
  text-transform: uppercase;
  color: #007bff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.featuresAddToChrome:hover {
  background-color: #007bff;
  color: white;
}

.resetResume {
  border: none;
  outline: none;
  background-color: orange;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
  word-wrap: normal;
}

@media screen and (max-width: 768px) {
  .featuresDiv {
    grid-template-columns: 1fr;
  }

  .featuresChild0Content {
    border-style: none;
    box-shadow: 0 0 24px 4px hsl(0deg 0% 60% / 16%);
    opacity: 1;
    padding: 5px;
    padding-bottom: 10px;
    margin: 0px;
  }

  .featuresChild1 {
    grid-row: 1;
  }
  .featuresChild0 {
    width: 100%;
    margin-top: 50px;
  }
  .featuresChild0Content {
    width: 100%;
  }

  .custom-modal-width {
    max-width: 100%;
  }

  .custom-modal-width .modal-body {
    padding: 5px;
  }
}
