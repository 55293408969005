.emailAutomationJobCard {
  background-color: aliceblue;
  height: 100%;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.emailAutomationJobCardCol {
  margin-bottom: 20px;
}

.emailAutomationJobsNotFoundBlock {
  box-shadow: 0px 1px 1px 2px #efefef, 0px 1px 1px 2px #efefef;
  padding: 50px 20px;
  margin: 20px;
  color: darkblue;
  font-weight: bold;
  width: 100%;
  font-size: 20px;
  text-align: center;
}

.emailAutomationMainSelect {
  margin: 20px;
  display: flex;
}

.emailAutomationFilters {
  width: 100%;
  text-align: end;
  display: block;
  margin: auto;
}

.emailAutomationSubmitButton {
  margin: 40px 0px;
}

.emailAutomationSelectAllButton {
  background: #007bff;
  color: white;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 10px;
  width: 200px;
}

.emailAutomationJobCard.selected {
  border: 2px solid black;
}

.jobCardName {
  font-weight: bold;
  color: darkblue;
}

.jobCardLocation {
  font-size: 15px;
  margin: 10px 0px;
  color: darkblue;
}

.jobCardLocation i {
  color: black;
  font-size: 15px;
}

.jobCardCompanyName {
  color: darkblue;
}

.jobCardCompanyName span {
  color: darkblue;
  font-weight: bold;
}

.jobCardName i {
  cursor: pointer;
}
