.main-referral-email-title {
  margin: 20px;
}

.main-referral-email-body {
  margin: 20px;
}

.main-referral-emailsend-button {
  margin: 20px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.main-referral-emailsend-button button {
  border: none;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  outline: none;
  border-radius: 5px;
  margin-left: 20px;
}

.main-referral-goback {
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: right;
  align-items: center;
}

.main-referral-email-error-activate {
  background-color: oldlace;
  padding: 20px;
  color: black;
  border: 2px solid red;
  border-radius: 5px;
}

.main-referral-email-error-createprofile{
    outline: none;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 20px;
}

.main-referral-emamil-error-createprofile-block p{
    font-size: 16px;
    color:blue;
    cursor: pointer;
    margin-bottom: 20px;
}

.main-referral-emamil-error-createprofile-block{
    margin:20px 0;
}

.main-referral-email-error-button {
  outline: none;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: green;
  color: white;
  font-weight: bold;
}
