.mainprivacy {
    margin-top: 80px;
    text-align: left;
    margin: 20px 50px;
    margin-bottom:100px;
    padding: 50px;
    box-shadow: 1px 1px 1.5px 1.5px #efefef, 1px 1px 1.5px 1.5px #efefef;
  }

  
  .mainprivacy h1 {
    color: black;
    font-size: 28px;
    margin-bottom: 20px;
  }

  .mainprivacyh{
    font-weight:bold;
    font-size:32px;
}
  
  .mainprivacy span {
    color: grey;
  }