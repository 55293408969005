.testingContainer {
  padding: 2rem;
  background-color: white;
  color: #333;
  font-family: Arial, sans-serif;
  position: relative;
}

.testingContainer .buttonGoBackV3 {
  position: absolute;
  top: 25px;
  right: 10px;
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
}

.testingContainer .replaceResume{
  right: 130px;
  background-color: green;
  color: white;
  font-weight: bold;
}

.testingContainer h1 {
  margin-bottom: 2rem;
  color: #333;
  font-size: 2em;
  font-weight: lighter;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.testingContainer .card {
  border: none;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  border-radius: 10px;
  background-color: aliceblue;
  transition: all 0.3s ease;
}

.testingContainer .header {
  background-color: #f0f8ff;
  color: #333;
  font-weight: lighter;
  padding: 5px 5px;
  font-size: 1em;
  border-bottom: 1px solid #ccc;
}

.card-body {
  background-color: #fff;
  padding: 2rem;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.testingContainer .form-group {
  margin-bottom: 1.5rem;
}

.testingContainer .form-label {
  font-weight: 500;
  color: #333;
  text-align: left;
}

.testingContainer .btn-primary {
  background-color: #4a90e2;
  border: none;
  color: white;
  padding: 0.75rem 1.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 0.5rem 0.25rem;
  cursor: pointer;
  transition-duration: 0.4s;
  border-radius: 5px;
}

.testingContainer .btn-primary:hover {
  background-color: #007bff;
  border: 2px solid #007bff;
}

.testingContainer .list-group-item {
  background-color: #f8f9fa;
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.testingContainer .work-experience-card {
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  transition: 0.3s;
}

.testingContainer .work-experience-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.testingContainer .icons {
  align-self: start;
}

.testingContainer .icon {
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
}

.work-experience-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.icons {
  display: flex;
  align-items: center;
}

.icon {
  cursor: pointer;
  margin-left: 15px;
}

/* Additional styles for improved form appearance */

.testingContainer form {
  width: 100%;
}

.testingContainer .form-control {
  border-radius: 5px;
}

.testingContainer .form-check-label {
  font-weight: normal;
}

.testingContainer .form-check-input {
  margin-right: 0.5rem;
}

.testingContainer .alert {
  margin-top: 1rem;
}

.testingContainer .form-row {
  align-items: center;
}

.testingContainer .form-row .col {
  margin-bottom: 1.5rem;
}

.testingContainer textarea {
  resize: none;
}

.testingContainer select {
  border-radius: 5px;
}

.testingContainer .add-experience-btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.testingContainer .add-experience-btn-center {
  margin-top: 20px;
  margin-bottom: 20px;
}

.testingContainer .add-experience-btn-sm {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.8rem;
}

.testingContainer .experience-section {
  border-bottom: 1px solid #dee2e6;
  padding: 20px 0;
  text-align: left;
}

.testingContainer .experience-details {
  margin-left: 20px;
}

.testingContainer .job-title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 5px;
}

.testingContainer .job-company {
  display: flex;
}

.testingContainer .company-name {
  font-size: 1rem;
  color: #555555;
  margin-bottom: 10px;
}

.testingContainer .date-range {
  color: #777777;
  font-size: 1rem;
  margin-bottom: 10px;
}

.testingContainer .salary {
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

.testingContainer .description {
  color: #555555;
  margin-bottom: 0;
  font-size: 1rem;
}

.testingContainer .experience-maindiv {
  display: flex;
}

.testingContainer .experience-mainicon img {
  width: 50px;
}

.testingContainer .achievement-mainicon img {
  width: 50px;
}
.testingContainer .experience-icons {
  background: white;
  padding-top: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: end;
}

.testingContainer .experience-icons i {
  margin-right: 10px;
  cursor: pointer;
}

.testingContainer .add-experience-btn {
  margin-left: auto;
  display: block;
  font-size: 0.8rem;
  padding: 10px;
  background-color: #007bff;
}

.testingContainer .addskill {
  justify-content: center;
  align-items: center;
  margin: 0px;
  width: 100%;
}

.testingContainer .addskill button {
  width: 100%;
}

.testingContainer .empty-experience {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  border-top: 1px solid #dee2e6;
}

.testingContainer .empty-experience .btn {
  margin: auto;
}

.testingContainer .skills-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 10px;
}

.testingContainer .skill-button {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #f8f9fa;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #333;
}

.testingContainer .skill-button .skill-name {
  margin-right: 5px;
}

.testingContainer .skill-button .delete-skill {
  cursor: pointer;
  font-weight: bold;
}

.testingContainer .save-button-container {
  position: sticky;
  bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  background-color: #f7f7f7;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.05);
}

.testingContainer .btn-save {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  width: 250px;
  border-radius: 5px;
  background-color: #5cb85c;
  color: white;
  border: none;
}

.testingContainer .btn-save:hover {
  background-color: #449d44;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.skeleton-loading {
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 20px;
  animation: loading-animation 1.5s ease-in-out infinite;
}

.skeleton-loading {
  background-color: #f5f5f5;
}

.skeleton-loading.header {
  height: 40px;
}

.skeleton-loading.body {
  height: 200px;
}

.skeleton-loading-referral{
  margin: 0px !important;
  padding: 20px !important;
  margin: 20px 0px!important;
}

.skeleton-loading-referral .skeleton-loading {
  margin-bottom: 0px!important;
}

@keyframes loading-animation {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}

.testingContainer .form-label {
  color: slategray;
  text-align: left;
  display: block;
}

.testingContainer .input-container {
  display: flex;
  align-items: center;
}

.testingContainer .input-icon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.testingContainer .input-icon {
  width: 50px;
  height: auto;
}

.testingContainer .input-field {
  flex: 1;
}

.uploadbox {
  display: block;
  width: 100%;
  margin:20px 0 40px 0px;
}

.uploadbox p{
  font-weight: bold;
}

.resume-view {
  display: flex;
  flex-direction: row;
  background-color: aliceblue;
  border-radius: 10px;
}

.resume-view-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resume-load {
  /*center content*/
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resume-load .loading {
  position: relative;
  background-color: lightgray;
  min-width: 10px;
  min-height: 10px;
  height: 10px;
  border-radius: 1em;
}


.resume-view-label {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.resume-view-img .stripeimgsecure {
  margin: 0px;
}

.resume-view-cancel {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  background-color: #007bff;
  color:white;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 10px 20px;
}

@media screen and (max-width: 768px) {
  .testingContainer{
    padding:10px
  }

  .testingContainer .card{
    padding: 5px;
  }

  .experience-icons{
    margin-bottom: 10px;
  }

  .testingContainer .skill-button{
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #f8f9fa;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding:5px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #333;
  }

  .testingContainer h1{
    padding-top: 100px;
  }

  .testingContainer .card-body{
    padding:10px;
  }

}

