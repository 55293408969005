@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Lato:700);

.wrapper-card {
  display: flex;
  flex-wrap: nowrap;
  margin: 70px auto;
  width: 77%;
}

.card {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  flex: 1;
  border-radius: 30px;
  margin: 8px;
  padding: 30px;
  position: relative;
  text-align: center;
  transition: all 0.5s ease-in-out;
}
.card.popular {
  margin-top: -10px;
  margin-bottom: -10px;
}
.card.popular .card-title h3 {
  color: #3498db;
  font-size: 16px;
  font-weight: bold;
}
.card.popular .card-price {
  margin: 10px;
}
.card.popular .card-price h1 {
  color: #3498db;
  font-size: 40px;
  font-family: "Rubik", sans-serif;
}
.card.popular .card-action button {
  background-color: #3498db;
  border-radius: 80px;
  color: #fff;
  font-size: 17px;
  margin-top: -15px;
  padding: 10px;
  height: 80px;
}
.card.popular .card-action button:hover {
  background-color: #2386c8;
  font-size: 22px;
}
.card:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.card-ribbon {
  position: absolute;
  overflow: hidden;
  top: -10px;
  left: -10px;
  width: 114px;
  height: 112px;
}
.card-ribbon span {
  position: absolute;
  display: block;
  width: 160px;
  padding: 10px 0;
  background-color: #3498db;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  left: -35px;
  top: 25px;
  transform: rotate(-45deg);
}
.card-ribbon::before,
.card-ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
  border-top-color: transparent;
  border-left-color: transparent;
}
.card-ribbon::before {
  top: 0;
  right: 0;
}
.card-ribbon::after {
  bottom: 0;
  left: 0;
}

.card-title h3 {
  text-transform: uppercase;
  color: #3498db;
  font-size: 16px;
  font-weight: bold;
}
.card-title h4 {
  color: #3498db;
}

.card-price {
  margin: 10px 0;
  font-family: "Rubik", sans-serif;
}
.card-price h1 {
  font-size: 40px;
}
.card-price h1 sup {
  font-size: 15px;
  display: inline-block;
  margin-left: -20px;
  width: 10px;
}
.card-price h1 small {
  color: #3498db;
  display: block;
  font-size: 15px;
  text-transform: uppercase;
}

.card-description ul {
  display: block;
  list-style: none;
  margin: 40px 0;
  padding: 0;
  text-align: left;
  height: auto;
}
.card-description li {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  margin: 0 0 15px 20px;
}

.card-description li:before {
  content: "\f105"; /* FontAwesome Unicode */
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
  font-weight: 900;
  color: #007bff;
}
.card-action button {
  background: transparent;
  border: 2px solid #3498db;
  border-radius: 30px;
  color: #3498db;
  cursor: pointer;
  display: block;
  font-size: 15px;
  font-weight: bold;
  padding: 15px;
  width: 100%;
  height: 60px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.card-action button:hover {
  background-color: #3498db;
  box-shadow: 0 2px 4px #196090;
  color: #fff;
  font-size: 17px;
}

.small,
small {
  margin-left: 10px;
}

.pricingnext {
  width: 100%;
  padding: 10px;
}

.discount {
  font-size: 15px;
  color: black;
}
.discount img {
  width: 30px;
  height: 20px;
  margin-left: 10px;
}

.cutprice {
  font-size: 20px;
  position: relative;
  margin-right: 5px;
}
.cutprice1 {
  font-size: 20px;
  position: relative;
  margin-right: 20px;
}

.cutpricezip {
  position: relative;
  margin-right: 20px;
}
.cutpricezip::after {
  content: "";
  height: 2px;
  width: 170px;
  background-color: red;
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: 10000;
  transform: rotate(8deg);
}

.cutprice1::after {
  content: "";
  height: 2px;
  width: 60px;
  background-color: red;
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: 10000;
  transform: rotate(25deg);
}

.cutprice:after {
  content: "";
  height: 2px;
  width: 80px;
  background-color: red;
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: 10000;
  transform: rotate(25deg);
}

.fa-dollar-sign {
  font-size: 25px;
  margin-right: 10px;
}

.pricingCont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricingimg {
  width: 100%;
}

.lifetime {
  font-size: 20px !important;
  color: darkorange !important;
}

.cardone {
  border-top: 10px solid #3498db;
}

.card-price {
  color: #3498db;
}

#inputname {
  border-radius: 5px;
}

.referral {
  margin-bottom: 40px;
}

.referral .col-lg-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.referral .col-lg-4 {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.referralCode {
  font-size: 15px;
  font-weight: bold;
  color: black;
  background-color: orange;
  padding: 10px 5px;
  border-radius: 5px;
}

.referralCode.referralCodeApplied {
  background-color: green !important;
  color: white !important;
}

.alert {
  margin: 0px !important;
  background-color: #3598da !important;
  color: white !important;
  display: block !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 2px !important;
  position: relative !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  z-index: 100000;
}

.alert-dismissible .close {
  padding: 0.5rem 1.25rem !important;
}

.alert-heading {
  font-size: 16px !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 7px !important;
}

.mainTime {
  color: #ffd700;
  background-color: black;
  border-radius: 5px;
  padding: 8px;
  font-weight: bold;
  font-size: 17px;
  margin-left: 5px;
}

.planOutside {
  margin: 55px;
  margin-top: 0px;
}

.planOutside .card-title h3 {
  color: #faba3c !important;
  font-weight: bold !important;
}
.reviews {
  font-size: 30px;
  color: #3598da;
  font-weight: bold;
  overflow: hidden;
  margin: 20px 160px !important;
  margin-bottom: 40px !important;
}

.reviews p {
  cursor: pointer;
}

.fa-external-link-alt {
  font-size: 15px;
}

.pricingheading {
  text-align: center;
}

.pricingheading1 {
  font-size: 30px;
  font-weight: bold;
  color: black;
  margin: 10px 0;
  margin-top: 30px;
}

.pricingheading2 {
  font-size: 30px;
  color: black;
  margin: 10px 0;
}

.pricingheading3 {
  font-size: 30px;
  font-weight: bold;
  color: orange;
  margin: 0px;
}

.reviews img {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 2px solid;
}

@media screen and (max-width: 600px) {
  .planOutside {
    margin: 20px;
  }

  .pricingheading1 {
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin: 10px 0;
    margin-top: 30px;
  }

  .pricingheading2 {
    font-size: 15px;
    color: black;
    margin: 10px 0;
  }

  .pricingheading3 {
    font-size: 20px;
    font-weight: bold;
    color: orange;
    margin: 0px;
  }

  .planOutside .col-lg-4 {
    margin-bottom: 60px;
    margin-top: 60px;
    display: flex;
  }

  .alert-heading {
    background-color: #3598da !important;
    color: white !important;
    display: block !important;
    padding: 5px !important;
    margin-right: 20px !important;
    text-align: left !important;
  }

  .mainTime {
    display: inline-block;
    margin-top: 7px;
  }
}

.buttonDisabled {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  cursor: default !important;
}

/* bnpl */
/* stripe */

.Demo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stripeform label {
  color: darkblue;
  font-weight: bold;
  letter-spacing: 0.025em;
}

.stripeform button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 60px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
  border-radius: 10px;
}

.stripeform button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.stripeform input,
.stripeform .StripeElement {
  display: block;
  margin: 5px 0 10px 0;
  /* max-width: 500px; */
  padding: 5px 10px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1.5px solid #007bff;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.errorstripe {
  color: red;
  font-weight: bold;
}

.stripeform input::placeholder {
  color: #aab7c4;
}

.stripeimg {
  width: 250px;
  margin-left: 20px;
}

.stripeform input:focus,
.stripeform .StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.stripeform .StripeElement.IdealBankElement,
.stripeform .StripeElement.FpxBankElement,
.stripeform .StripeElement.PaymentRequestButton {
  padding: 0;
}

.stripeform .StripeElement.PaymentRequestButton {
  height: 40px;
}

.stripebuttondisable {
  background-color: gray !important;
  color: black !important;
}

.stripemodal .modal-content {
  background-color: bisque;
}

.stripemodal {
  z-index: 1000000;
}

.stripeimgsecure {
  height: 40px;
  margin-left: 10px;
}

@media screen and (max-width: 990px) {
  .stripeimg {
    width: 200px;
  }
}

@media screen and (max-width: 495px) {
  .stripeimg {
    margin-left: 0px;
  }
}

.bnplbutton {
  background-color: #80e1d8;
  margin-bottom: 30px;
}

.aboutsplit {
  text-align: left !important;
  font-size: 14px !important;
  color: #007bff !important;
  padding-left: 10px;
  cursor: pointer;
}

.buttonsplit {
  background: aqua;
  margin-bottom: 30px;
}

.buttonsplit:hover {
  color: color !important;
}

/* split payments */
.splitPaymentsContainer{
  padding: 10px;
}

.splitPaymentsContainer img{
  width: 100%;
  height: auto;
}


/* split payments */
.splitPaymentsContainer {
  padding: 10px;
}

.splitPaymentsContainer img {
  width: 100%;
  height: auto;
}

.splitCircular {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 100px;
}

.splitPaymentsTitle {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.halfamount {
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  color: #0069d9;
  font-weight: bold;
  margin-bottom: 5px;
}

.halfamountDesc {
  font-weight: bold;
  color: black;
  font-size: 16px;
  text-align: center;
  margin-top: 0px;
}

.splitMainText {
  text-align: center;
  font-weight: bold;
}


.calendarlink{
  background: #007bff!important;
    color: white!important;
    font-size: 20px!important;
}


.freePlan {
  margin: 70px auto;
  width: 77%;
  background-color: #0b0b0b;
  display: grid;
  height: auto;
  padding: 20px 0;
  border-radius: 15px;
  overflow-x: hidden;
  grid-template-columns: 4fr 1fr;
}

.freePlanChild0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 40px;
  font-size: 35px;
  color: #fff;
  text-transform: uppercase !important;
}

.freePlanChild1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.freePlanChild0 p {
  color: #f2fa8c;
  font-size: 25px;
  text-transform: none;
}

.freePlanChild1 img {
  width: 130px;
  height: auto;
}

.cardImage {
  width: 50px;
  margin: 10px;
  display: inline-block;
}

@media screen and (max-width: 992px) {
  .freePlan {
    width: 90%;
    grid-template-columns: 4fr 2fr;
  }
}