.sidebar {
  background-color: #f7f7f7;
  display: flex;
  /* grid-template-rows: 1fr 3fr 2fr; */
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 0;
  position: relative;
  top: 0;
  left: -40%;
  transition: 0.7s;
  height: initial;
}

.account_details{
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  color:darkcyan
}

.sidebar.sidebar_open {
  width: 350px;
  left: 0%;
}

.sidebarTop {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  border-radius: 50% 50% 0 0;
  /* width: 240px; */
}

.targetedsearchnav {
  /* font-weight: bold; */
  /* color: black; */
}

.sidebarImageContainer {
  /* background-color: #faf8f2; */
  justify-content: center;
  align-items: center;
  display: flex;
  height: 150px;
  width: 150px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.sidebarTop img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.welcomeback {
  font-size: 22px;
  color: black;
}

.name,
.email,
.welcomeback {
  text-align: center;
  margin: 5px;
}

.email {
  font-size: 12px;
  color: grey;
}

.name {
  font-size: 17px;
  font-weight: bold;
}

.sidebarUl {
  margin-top: 25px;
}

.sidebarUlChild {
  display: grid;
  padding: 10px;
  margin-top: 5px;
  grid-template-columns: 1fr 4fr;
  gap: 10px;
  color: grey;
  cursor: pointer;
  position: relative;
  border: 2px solid #f0f4ff;
}

.sidebarUlChild:hover {
  color: black;
  text-decoration: none;
  text-transform: none;
}

.sidebarUlChild:hover i {
  color: black;
  text-decoration: none;
  text-transform: none;
}

.sidebarUlChild:hover::before {
  background-color: #d7e1ff;
}

.active .sidebarUlChild {
  color: black;
}

.sidebarUlChild::before {
  content: " ";
  width: 5px;
  background-color: #f0f4ff;
  position: absolute;
  height: 100%;
}

.sidebarUlChild0 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: grey;
}

.active .sidebarUlChild i {
  color: black;
}

.active .sidebarUlChild::before {
  background-color: #d7e1ff;
}

.sidebarMiddle {
  height: 100%;
  width: 100%;
  background-color: white;
}

.sidebarFooter {
  /* height: 100%; */
  display: flex;
  padding-top: 100px;
  align-items: flex-end;
  background-color: white;
}

.sidebarFooterContent {
  margin: 10px;
  border-radius: 15px;
  background-color: #f0f4ff;
  padding-bottom: 10px;
}

.contact {
  width: 100%;
}

.contactUs {
  text-align: center;
  color: black;
  font-size: 14px;
}
.contactMain {
  text-align: center;
  position: relative;
}

.contactMain::after {
  content: " ";
  width: 25%;
  height: 1.5px;
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translate(-50%);
  background-color: grey;
}
.contactUs p {
  margin: 5px;
}

.getemailsnew {
  background-color: orangered;
  color: white;
  padding: 5px;
  margin-right: 7px;
  font-weight: bold;
  border-radius: 4px;
  font-size: 10px;
  display: inline-block;
}

.debugnow {
  background-color: #d7e1ff;
  color: black;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  font-weight: bold;
}

.circularDiv {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: darkcyan;
  color: white;
  text-align: center;
  line-height: 100px;
  font-size: 36px;
  font-weight: bold;
}

.roundedDiv {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: darkcyan;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.extra-buttons-container {
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  max-height: 0; /* Initially hidden */
}

.extra-buttons-container.show {
  max-height: 500px;
}

.sidebar_closed{
  display: none;
}

@media screen and (max-width: 768px) {

  .sidebarUl {
    margin-top: 0px;
  }

  .sidebar {
    position: fixed;
    height: 100%;
    z-index: 99;
    display: initial;
    overflow-y: scroll;
  }

  .sidebar_closed{
    display: block;
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;
    color:cadetblue;
    cursor: pointer;
    font-size: 30px;
  }

  .sidebar.sidebar_open {
    width: 300px;
    left: 0%;
  }
}
