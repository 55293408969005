@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@800&display=swap");
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

/* Hide the error message */
#webpack-dev-server-client-overlay {
  display: none;
}

.alert {
  margin: 0px !important;
  background-color: #3598da !important;
  color: white !important;
  display: block !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 2px !important;
  /* position: relative !important; */
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  z-index: 100000;
}

.alert-dismissible .close {
  padding: 0.5rem 1.25rem !important;
}

.alert-heading {
  font-size: 15px !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 5px !important;
}

.mainTimeBuy {
  background-color: #007bff;
  padding: 5px;
  margin-left: 5px;
  background-color: #f74301;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.freeforsometime{
  color:white;
  padding:5px;
  margin-left: 10px;
  background-color: black;
  border-radius: 10px;
}

[id^="webpack-dev-server"]{
  display: none;
}

.PhoneInput input{
  border: none!important;
}